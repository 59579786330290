import cn from 'classnames';
import TitleContainer from 'bundles/App/pages/Account/TitleContainer/TitleContainer';
import Grid from 'styleguide/components/Grid/Grid';
import css from './TaxExemptionsPage.scss';
import Footer from 'styleguide/components/Footer/Footer';
import * as React from 'react';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import { getTaxExemption } from 'api/account/taxExemptions';
import { Status } from 'libs/utils/api/types';
import Loader from 'styleguide/components/Loader/Loader';
import Span from 'styleguide/components/Span/Span';
import { A, Button } from 'styleguide/components';
import TaxExemptionsFormModal from 'bundles/App/pages/Account/TaxExemptions/TaxExemptionsFormModal/TaxExemptionsFormModal';
import AddressItem from 'bundles/App/pages/OrderDetailsPage/Address/Address';

export interface ExemptRegion {
  id: number;
  approved: boolean;
  state: { id: number; abbr: string; name: string };
  taxExemptionDocument: {
    url: string;
    name: string;
  };
}

export interface TaxExemption {
  id: number;
  address: Address;
  taxExemptionType: string;
  exemptRegions: ExemptRegion[];
}

const TaxExemptionsPage = () => {
  const [loaded, setLoaded] = React.useState<boolean>();
  const [taxExemption, setTaxExemption] = React.useState<TaxExemption>(null);
  const [showTaxExemptionFormModal, setShowTaxExemptionFormModal] = React.useState<boolean>(false);

  const getStatus = exemptRegion => {
    let status;

    if (exemptRegion.approved === null) {
      status = 'Pending';
    } else if (exemptRegion.approved) {
      status = 'Approved';
    } else {
      status = 'Rejected';
    }
    return status;
  };

  React.useEffect(() => {
    getTaxExemption().then(res => {
      if (res.status === Status.Ok) {
        setTaxExemption(res.payload.taxExemption);
      }
      setLoaded(true);
    });
  }, []);

  let taxExemptionContent;

  if (loaded) {
    if (taxExemption) {
      taxExemptionContent = (
        <>
          <Grid.Row>
            <Grid.Col>
              <AddressItem title="Company Address" address={taxExemption.address} />
              <Grid.Row>
                <Grid.Col className={css.exemptionInfoContainer}>
                  <div>{`Exemption type: ${taxExemption.taxExemptionType}`}</div>
                  <div>
                    <Span>Exemption Regions</Span>
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className={css.exemptionRow}>
            {taxExemption.exemptRegions.map((exemptRegion, i) => (
              <>
                <Grid.Col sm={3} hiddenMd="up" />
                <Grid.Col key={i} className={css.exemptionContainer} sm={6} md={4}>
                  <div className={cn(css.exemptionContainer, css.exemptionItem)}>
                    <div className={css.itemTitle}>{`${exemptRegion.state.name}`}</div>
                    <div className={css.itemContent}>
                      Certificate:{' '}
                      <A href={exemptRegion.taxExemptionDocument.url} color="blue" underline="always">
                        {exemptRegion.taxExemptionDocument.name}
                      </A>
                    </div>
                    <div className={css.itemContent}>Status: {getStatus(exemptRegion)}</div>
                  </div>
                </Grid.Col>
                <Grid.Col sm={3} hiddenMd="up" />
              </>
            ))}
          </Grid.Row>
          <Grid.Row>
            <Grid.Col className={css.buttonContainer}>
              <Button type="button" color="blue" onClick={() => setShowTaxExemptionFormModal(true)}>
                Edit Tax Exemptions
              </Button>
            </Grid.Col>
          </Grid.Row>
        </>
      );
    } else {
      taxExemptionContent = (
        <>
          <div className={css.verticalSpace}>You do not have any exemptions. </div>
          <Button type="button" color="blue" onClick={() => setShowTaxExemptionFormModal(true)}>
            Create Tax Exemption
          </Button>
        </>
      );
    }
  } else {
    taxExemptionContent = <Loader size="xl" color="blue" />;
  }

  return (
    <>
      {showTaxExemptionFormModal && (
        <TaxExemptionsFormModal
          taxExemption={taxExemption}
          setTaxExemption={setTaxExemption}
          onClose={() => setShowTaxExemptionFormModal(false)}
        />
      )}
      <TitleContainer title="Tax Exemptions" />
      <Grid.Container>
        <Grid className={css.creditCardListContainer}>
          <Grid.Row>
            <Grid.Col>{taxExemptionContent}</Grid.Col>
          </Grid.Row>
        </Grid>
      </Grid.Container>
      <Footer />
    </>
  );
};

export default TaxExemptionsPage;
